import styles from './BetHistoryExpandBonus.module.scss';
import { useTranslation } from "react-i18next";
import { IBetHistoryExpandBonus } from '../../common/interface';

export default function BetHistoryExpandBonus({ data, assetPath, currency }: IBetHistoryExpandBonus) {

	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<table>
				<thead>
					<tr>
						<th>{t('expand_payout.win_icon')}</th>
						<th>{t('expand_payout.gross_win', { currency: currency })}</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((expandPayout, index) => (
						<tr key={index}>
							<td>
								<img src={`${assetPath + `/${expandPayout.symbol.toString()}`}.png`} alt="Expand" className={styles.expand_payout_image} />
							</td>
							<td>{expandPayout.payout}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}