import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		supportedLngs: ['en', 'zh_CN', 'zh_TW'],
		fallbackLng: 'en',
		lng: 'en',
		backend: {
			loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/translation.json'
		},
		nonExplicitSupportedLngs: false,
	});

export default i18n;