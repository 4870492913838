import { useTranslation } from 'react-i18next';
import styles from './NoRecord.module.scss';

export default function NoRecord() {

	const { t } = useTranslation();

	return (
		<div className={styles.no_record_found}>
			{t('error_msg.no_record')}
		</div>
	);
}