import { useTranslation } from "react-i18next";
import styles from './BetHistoryResult.module.scss';
import { IBetHistoryResult } from '../../common/interface';
import BetHistory from '../BetHistory';

export default function BetHistoryResult({ gameType, symbolResult, betDetails, assetPath, height, codeId, currentIndex, showFreeGame, setShowFreeGame, showFreeGameBtn }: IBetHistoryResult) {
	const { t } = useTranslation();
	const handleShowFreeGame = setShowFreeGame || (() => { });
	const symbols = symbolResult?.symbols;
	const recordsPerPage = (symbolResult?.totalRows ?? 0) * (symbolResult?.totalColumns ?? 0);
	const indexOfLastRecord = (currentIndex ?? 0) * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentSymbols = symbols?.slice(indexOfFirstRecord, indexOfLastRecord);
	const gameResultContainer = {
		display: 'grid',
		gridTemplateRows: `repeat(${symbolResult?.totalRows}, 1fr)`,
		gridTemplateColumns: `repeat(${symbolResult?.totalColumns}, 1fr)`,
	};

	const gameResultRowSpan = {
		gridRow: `span ${symbolResult?.totalRows}`
	};

	const isExpandSymbol = new Map<string, string[]>([
		["SL12", ["202", "203", "204"]],
		["SL16", ["202", "203", "204"]],
		["SL39", ["202"]],
		["SL10", ["202"]]
	]);
	const isExpandMapping = new Map<number, string>();

	const handleButtonClick = () => {
		handleShowFreeGame((prevState: boolean) => !prevState);
	};


	return (
		<div className={styles.container}>
			<div id="GameResultContainer" className={styles.game_result_container} style={gameResultContainer}>
				{currentSymbols?.map((symbol, index) => {
					const result = (!isExpandMapping.get(index % (symbolResult?.totalColumns ?? 1))) ? (
						<div className={styles.result} style={isExpandSymbol.get(codeId ?? '')?.some(s => s == symbol) ? gameResultRowSpan : {}} key={index}>
							<img className={styles.result_image} src={`${assetPath}/${symbol}.png`}></img>
						</div>
					) : null;

					if (isExpandSymbol.get(codeId ?? '')?.some(s => s == symbol)) {
						isExpandMapping.set(index, symbol);
					}
					return result;
				})}
			</div>
			<div className={styles.bet_detail}>
				{gameType && <div className={styles.info_item} key={gameType}>
					<div className={styles.label}>{t('bet_details.bet_slot_type')}</div>
					<div className={styles.value}>{t(`game_type.${gameType}`)}</div>
				</div>}
				{gameType === 1 && showFreeGameBtn ? (
					<button className={styles.btn_show_free_game} onClick={handleButtonClick}>{showFreeGame ? t('bet_details.hide_free_game') : t('bet_details.show_free_game')}</button>
				) : (
					<div className={styles.info_item} key={`${gameType}-empty`} />
				)}
				{betDetails?.map(info => (
					<div className={styles.info_item} key={info.label}>
						<div className={styles.label}>{info.label}</div>
						<div className={styles.value}>{info.value}</div>
					</div>
				))}
			</div>
		</div>
	);
}