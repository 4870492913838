import styles from './BetHistoryTitle.module.scss';
import { useTranslation } from "react-i18next";
import { IBetHistoryTitle } from '../../common/interface';
import { isValidDate } from '../../common/utility';

export default function BetHistoryTitle({ loginName, billId, billTime, isSub }: IBetHistoryTitle) {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			{!isSub && <div className={styles.info}>
				<h1>{t('user.account_name')} : </h1>
				<h1>{loginName}</h1>
			</div>}
			<div className={styles.info}>
				<h1>{t('round_info.bill_id')} : </h1>
				<h1>{billId}</h1>
			</div>
			<div className={styles.info}>
				<h1>{t('round_info.bill_time')} : </h1>
				<h1>{billTime}</h1>
			</div>
		</div>
	);
}