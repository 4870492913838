import styles from './BetHistoryPayout.module.scss';
import { useTranslation } from "react-i18next";
import { IBetHistoryPayout } from '../../common/interface';

export default function BetHistoryPayout({ data, currency, currentIndex, showMultiplier }: IBetHistoryPayout) {
	const { t } = useTranslation();

	const currentRoundData = data?.filter(p => (p.round ?? currentIndex) == currentIndex);

	return (
		<div className={styles.container}>
			<table>
				<thead>
					<tr>
						<th>{t('bet_payout.line')}</th>
						<th>{t('bet_payout.bet', { currency: currency })}</th>
						{showMultiplier && <th>{t('bet_payout.multiplier')}</th>}
						<th>{t('bet_payout.payout', { currency: currency })}</th>
						<th>{t('bet_payout.win_lose', { currency: currency })}</th>
					</tr>
				</thead>

				<tbody>
					{currentRoundData?.map((row, index) => (
						<tr key={index}>
							<td>{row.line}</td>
							<td>{row.totalBet}</td>
							{showMultiplier && <td>{row.multiplier}</td>}
							<td>{row.payout}</td>
							<td>{row.grossWin}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}