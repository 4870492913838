import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { fixed2WithComma, isValidDate } from '../common/utility';
import { IBetHistoryResponse, IBetHistoryDetailsResponse, IBetHistoryTitle, IBetHistoryResult, IBetHistoryScatterBonus, IBetHistorySpecialSymbolBonus, IBetHistoryPayout, IBetHistoryExpandBonus, IBetHistoryWinningWayBonus, IExpandSymbolPayoutData, IPayoutData, ISpecialSymbolData, IWinningWayPayoutData, IBetHistoryJackpot } from './../common/interface';
import { formatDate, getWinningTypeName } from '../common/utility';
import { XIN } from '../common/constant';

export default function useBetHistory(endpoint: string, billremark?: string, loginname?: string, billtime?: string, currency?: string) {
	const { t } = useTranslation();
	dayjs.extend(utc);
	const isXIN = window.__env__?.REACT_APP_OPERATOR_ID == XIN;

	const fetchBetData = async () => {
		try {
			const response = await axios.get(endpoint);
			const data: IBetHistoryResponse = response.data;
			return data;
		} catch (error) {
			console.error('Error fetching bet history data: ', error);
		}
	};

	const transformData = async (data: IBetHistoryDetailsResponse) => {
		const playerCurrency = isXIN ? (currency ?? '?') : data.playerCurrency;
		const showMultiplier = data.codeId === 'SL21';

		const winningWayPayoutData: IWinningWayPayoutData[] = data?.winningWayPayout?.map((item: any) => ({
			payout: fixed2WithComma(item.payout),
			winSymbol: item.winSymbol,
			multiplier: item.multiplier,
			totalSymbol: item.totalSymbol,
			round: item.round,
			rowmax: item.rowmax
		}));

		const specialSymbolData: ISpecialSymbolData[] = data?.specialSymbolPayout?.map((item: any) => ({
			payout: fixed2WithComma(item.payout),
			symbol: item.symbol
		}));

		const expandSymbolData: IExpandSymbolPayoutData[] = data?.expandSymbolPayout?.map((item: any) => ({
			payout: fixed2WithComma(item.payout),
			symbol: item.symbol
		}));

		const betPayoutData: IPayoutData[] = (await getBetDetailList(data))?.map((item: any) => ({
			grossWin: fixed2WithComma(item.grossWin),
			line: item.line,
			multiplier: item.multiplier,
			payout: fixed2WithComma(item.payout),
			symbol: item.symbol,
			totalBet: fixed2WithComma(item.totalBet),
			winCount: item.winCount,
			round: item.round,
		})).filter((item: IPayoutData) => item.line !== 0 && item.line < 300);

		return {
			betHistoryFullDetails: data,
			titleData: {
				loginName: isXIN ? loginname : data.playerUsername,
				billId: isXIN ? billremark : data.roundId,
				billTime: isXIN ? isValidDate(billtime ?? '') ? dayjs.utc(data.createdAt).local().format('YYYY-MM-DD hh:mm:ss A') : t('error_msg.invalid_date') + `(${billtime})` : dayjs.utc(data.createdAt).local().format('YYYY-MM-DD hh:mm:ss A')
			},
			betResultData: {
				symbolResult: { symbols: await getSymbols(data), totalRows: await getRows(data), totalColumns: await getReels(data) },
				gameType: data.betSlotType,
				betDetails: [
					{ label: t('bet_details.total_bet_amount', { currency: playerCurrency }), value: fixed2WithComma(data.totalBet) },
					{ label: t('bet_details.total_gross_win', { currency: playerCurrency }), value: fixed2WithComma(data.totalPayout) },
					{ label: t('bet_details.win_lose', { currency: playerCurrency }), value: fixed2WithComma(data.totalGrossWin) },
					{ label: t('bet_details.balance_after', { currency: playerCurrency }), value: fixed2WithComma(data.balanceAfter) }
				],
				assetPath: process.env.PUBLIC_URL + `/assets/${data.codeId}`,
				height: 0,
				currentIndex: 1,
				codeId: data.codeId
			},
			scatterResultData: {
				data: {
					multiplier: data?.scatterPayout?.multiplier,
					payout: fixed2WithComma(data?.scatterPayout?.payout),
					symbol: data?.scatterPayout?.symbol,
					totalSymbol: data?.scatterPayout?.totalSymbol,
				},
				currency: playerCurrency,
				assetPath: process.env.PUBLIC_URL + `/assets/${data.codeId}`
			},
			specialSymbolResultData: {
				data: specialSymbolData,
				assetPath: process.env.PUBLIC_URL + `/assets/${data.codeId}`,
				currency: playerCurrency
			},
			expandSymbolResultData: {
				data: expandSymbolData,
				assetPath: process.env.PUBLIC_URL + `/assets/${data.codeId}`,
				currency: playerCurrency
			},
			winningWayBonusData: {
				data: winningWayPayoutData,
				assetPath: process.env.PUBLIC_URL + `/assets/${data.codeId}`,
				currency: playerCurrency,
				currentIndex: 1,
				codeId: data.codeId
			},
			betPayoutData: {
				data: betPayoutData,
				currency: playerCurrency,
				currentIndex: 1,
				showMultiplier: showMultiplier
			},
		};
	};

	const getRows = async (data: any) => {
		if (data?.codeId === 'SL21')
			return data.totalRows - 2;
		return data.totalRows;
	}

	const getReels = async (data: any) => {
		if (data?.codeId === 'SL21')
			return data.totalReels - 1;
		return data.totalReels;
	}

	const expandSymbols = async (data: any, totalRows: number, totalReels: number, wildSymbol: string, expandWildSymbol: string) => {
		let symbols: string[] = data;
		const pageSize = totalRows * totalReels;

		const processPage = (pageSymbols: string[]) => {
			const symbolMatrix = Array.from({ length: totalRows }, (_, i) => pageSymbols.slice(i * totalReels, (i + 1) * totalReels));
			for (let col = 0; col < totalReels; col++) {
				if (symbolMatrix.every(row => row[col] === wildSymbol)) {
					for (let row = 0; row < totalRows; row++) {
						symbolMatrix[row][col] = expandWildSymbol;
					}
				}
			}
			return symbolMatrix.flat();
		};

		const totalPages = Math.ceil(symbols.length / pageSize);

		if (totalPages > 1) {
			let processedSymbols: string[] = [];
			for (let i = 0; i < totalPages; i++) {
				processedSymbols = [
					...processedSymbols,
					...processPage(symbols.slice(i * pageSize, (i + 1) * pageSize))
				];
			}
			symbols = processedSymbols;
		} else {
			symbols = processPage(symbols);
		}
		return symbols;
	};

	const getSymbols = async (data: any) => {
		let symbols: any[] = data?.resultString?.split(',').map(String);
		// additionalResultStr
		if (data.additionalResultStr && data.additionalResultStr != "") {
			symbols.push.apply(symbols, data.additionalResultStr.split(';').filter((s: any) => s != "").join().split(',').filter((s: any) => s != "").map(String));
		}
		// Cascade
		if (data.cascadeResultString && data.cascadeResultString != "") {
			symbols = data.cascadeResultString.split(';').filter((s: any) => s != "").join().split(',').filter((s: any) => s != "").map(String);
		}
		// respinResultString
		if (data.respinResultString && data.respinResultString != "" || data.mysteryResultString && data.mysteryResultString != "") {
			symbols = [];
			//SL10, SL39
			if (['SL10', 'SL39'].includes(data?.codeId)) {
				data.respinResultString.split('|').filter((s: any) => s != "").forEach((p: any) => {
					const parts = p.split(';').filter((s: any) => s != "");
					if (parts.length === 2) {
						symbols.push.apply(symbols, parts[1].split("&")[0].split(',').filter((s: any) => s != "").map(String));
					} else {
						symbols.push.apply(symbols, parts[0].split("&")[0].split(',').filter((s: any) => s != "").map(String));
					}
				});
			}
			else if (['SL2'].includes(data?.codeId) && data.mysteryResultString && data.mysteryResultString != "") {
				let splitResultString = data.mysteryResultString.split(';');
				let mysterySymbolResult;
				symbols = [];

				if (splitResultString.length) {
					let mysterySymbols = splitResultString[1].split(',').map((symbol: string) => Number.parseInt(symbol));
					mysterySymbolResult = await getMysterySymbolResult(mysterySymbols, splitResultString[2], splitResultString[3], data.betSlotType, data.totalRows, data.totalReels);
					symbols.push(...mysterySymbolResult, ...splitResultString[0].split(','));
				}
				else {
					symbols = splitResultString[0].split(',').map((symbol: string) => Number.parseInt(symbol));
				}
			}
			else {
				let splitData = data.respinResultString || data.mysteryResultString;
				splitData.split('|').filter((s: any) => s != "").forEach((p: any) => {
					p.split(';').filter((s: any) => s != "").forEach((include: any) => {
						symbols.push.apply(symbols, include.split("&")[0].split(',').filter((s: any) => s != "").map(String));
					});
				});
			}
		}

		//SL21 remove multiplier and empty symbol
		if (data.codeId === 'SL21') {
			symbols = symbols.filter((symbol: any) => parseInt(symbol) <= 300);
		}

		//Update expand symbols for older games (SL39)
		if (['SL39', 'SL10'].includes(data.codeId)) {
			let wildSymbol = '201';
			let expandWildSymbol = '202';
			symbols = await expandSymbols(symbols, data.totalRows, data.totalReels, wildSymbol, expandWildSymbol);
		}

		return symbols;
	}

	const getBetDetailList = async (data: any) => {
		if (data.respinResultString && data.respinResultString != "") {
			if (['SL10', 'SL39'].includes(data?.codeId)) {
				return GetAfterRespinBetDetailList(data)
			}
			else {
				// SL12, SL16
				return getRespinBetDetailList(data);
			}
		}

		if (data?.betDetailList?.length) {
			//SL22
			if (['SL2', 'SL33', , 'SL34', 'SL22', 'SL3'].includes(data?.codeId)) {
				return getBetDetailListWithRound(data);
			}
		}

		return data.betDetailList;
	}

	const getRespinBetDetailList = async (data: any) => {
		let rounds: any = [];
		let round = 1;
		data.respinResultString.split('|').filter((s: any) => s != "").forEach((p: any) => {
			p.split(';').filter((s: any) => s != "").forEach((include: any, index: any, array: any) => {
				rounds.push({
					round: round++,
					isIncluded: index != array.length - 1
				})
			});
		});

		// deep copy data.betDetailList to betDetailListWithRound
		let betDetailListWithRound = JSON.parse(JSON.stringify(data.betDetailList));

		let grouped = betDetailListWithRound.reduce((grouped: any, betDetail: any, index: any) => {
			let key = betDetail.line;
			if (!grouped[key]) {
				grouped[key] = [];
			}
			betDetail.round = rounds.filter((r: any) => !r.isIncluded)[grouped[key].length].round;
			grouped[key].push(betDetail);
			return grouped;
		}, {});

		return betDetailListWithRound;
	}

	//SL10
	const GetAfterRespinBetDetailList = async (data: any) => {
		let rounds: any = [];
		let round = 1;
		data.respinResultString.split('|').filter((s: any) => s != "").forEach((p: any) => {
			rounds.push({
				round: round++,
			})
		});

		// deep copy data.betDetailList to betDetailListWithRound
		let betDetailListWithRound = JSON.parse(JSON.stringify(data.betDetailList));

		betDetailListWithRound.reduce((grouped: any, betDetail: any, index: any) => {
			let key = betDetail.line;
			if (!grouped[key]) {
				grouped[key] = [];
			}
			betDetail.round = rounds[grouped[key].length].round;
			grouped[key].push(betDetail);
			return grouped;
		}, {});
		return betDetailListWithRound;
	}

	const getBetDetailListWithRound = async (data: any) => {
		let lineRounds: { [key: number]: number } = {};

		let betDetailListWithRound = JSON.parse(JSON.stringify(data.betDetailList));

		betDetailListWithRound.forEach((item: any) => {
			const line = item.line;
			if (lineRounds[line]) {
				lineRounds[line]++;
			} else {
				lineRounds[line] = data.mysteryResultString ? 2 : 1;
			}
			item.round = lineRounds[line];
		});
		return betDetailListWithRound;
	}

	const getMysterySymbolResult = async (
		symbols: number[],
		mysterySymbolBinary: string,
		transformTypeString: string,
		betSlotType: number,
		rows: number,
		columns: number
	) => {
		// Assuming mysterySymbolType is derived from transformTypeString or another parameter
		const mysterySymbolType = Number.parseInt(betSlotType == 1 ? '301' : '302');

		// Parse and reverse the binary string
		const mysterySymbolIndexPosition = Number.parseInt(mysterySymbolBinary).toString(2).split('').reverse();
		// Iterate through the binary positions
		for (let i = 0; i < mysterySymbolIndexPosition.length; i++) {
			if (mysterySymbolIndexPosition[i] === '1') {
				// Calculate the index in the symbols array
				const rowIndex = Math.floor(i / columns);
				const columnIndex = i % columns;
				const index = rowIndex * columns + columnIndex;

				// Replace the value in the symbols array
				if (index < symbols.length) {
					symbols[index] = mysterySymbolType;
				}
			}
		}

		return symbols;
	};

	return { fetchBetData, transformData };
};
