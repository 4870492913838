import { WinningType } from "./enum";

export function formatDate(dateStr: string) {
	const date = new Date(dateStr);
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // months are 0-based
	const day = date.getDate().toString().padStart(2, '0');
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

//Format date to ISO format without timezone conversion
export function formatDateTimeToISO(dateStr: string): string | Error {
	const utcDate = new Date(dateStr.replace(' ', 'T') + 'Z');

	if (isNaN(utcDate.getTime())) {
		return new Error("Invalid date format");
	}

	return utcDate.toISOString().slice(0, 19);
}

// Utility function to format and validate the date without time
export function formatDateToISO(dateStr: string): string | Error {
	// Regular expression to match YYYY-MM-DD format
	const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

	if (!dateRegex.test(dateStr)) {
		return new Error("Invalid date format");
	}

	const date = new Date(dateStr);
	const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

	if (isNaN(utcDate.getTime())) {
		return new Error("Invalid date format");
	}

	return utcDate.toISOString().slice(0, 10);
}

export function isValidDateTime(dateString: string): boolean {
	// Regex to check if the string is in the expected "YYYY-MM-DD HH:mm:ss" format
	const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
	if (!regex.test(dateString)) {
		return false;
	}

	const date = new Date(dateString);

	if (isNaN(date.getTime())) {
		return false;
	}

	const formattedString = date.getFullYear() + '-' +
		('0' + (date.getMonth() + 1)).slice(-2) + '-' +
		('0' + date.getDate()).slice(-2) + ' ' +
		('0' + date.getHours()).slice(-2) + ':' +
		('0' + date.getMinutes()).slice(-2) + ':' +
		('0' + date.getSeconds()).slice(-2);

	return dateString === formattedString;
}

export function isValidDate(dateString: string): boolean {
	// Regex to check if the string is in the expected "YYYY-MM-DD" format
	const regex = /^\d{4}-\d{2}-\d{2}$/;
	if (!regex.test(dateString)) {
		return false;
	}

	const date = new Date(dateString);

	if (isNaN(date.getTime())) {
		return false;
	}

	const formattedString = date.getFullYear() + '-' +
		('0' + (date.getMonth() + 1)).slice(-2) + '-' +
		('0' + date.getDate()).slice(-2)

	return dateString === formattedString;
}

export function getWinningTypeName(winType: number): string {
	return WinningType[winType];
}

/**
 * 
 * https://stackoverflow.com/a/14428340
 * @param v: value of the number
 * @param n: length of decimal
 * @param x: length of whole part
 * @param s: sections delimiter
 * @param c: decimal delimiter
 */
export function formatNumber(v: number, n: number, formatOutZero: boolean = false, x = 3, s = ',', c = '.'): string {
	const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
	const num = formatOutZero ? v.toFixed(Math.max(0, ~~n)).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
		: v.toFixed(Math.max(0, ~~n));

	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

export function fixed2WithComma(v: number | string | undefined) {
	return v != undefined && !isNaN(+v) ? formatNumber(+v, 2) : v;
}
export function getQueryParam(param: any) {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get(param);
};

export function splitStringByPrefix(str: string, prefixes: string[]): { prefix: string; rest: string } {
	// Convert the input string to lower case because KM too braindead to cater this
	const lowerStr = str.toLowerCase();

	// Find the matching prefix
	const matchedPrefix = prefixes.find(prefix => lowerStr.startsWith(prefix));

	// If a matching prefix is found, split the string
	if (matchedPrefix) {
		const parts = lowerStr.split(matchedPrefix);
		return {
			prefix: matchedPrefix,
			rest: parts[1] || ''
		};
	}

	return {
		prefix: '',
		rest: str
	};
}

export function stringToArray(input: string): string[] {
	return input.split(',').map(item => item.trim());
}