import styles from './BetHistory.module.scss';
import BetHistoryTitle from './bet-title/BetHistoryTitle';
import BetHistoryResult from './bet-result/BetHistoryResult';
import BetHistoryScatterBonus from './bet-scatter-bonus/BetHistoryScatterBonus';
import BetHistoryPayout from './bet-payout/BetHistoryPayout';
import BetHistorySpecialSymbolBonus from './bet-special-symbol-bonus/BetHistorySpecialSymbolBonus';
import config from '../../../src/config.json'
import { GameConfigMap, IBetHistoryResponse, IBetHistoryDetailsResponse } from '../common/interface';
import useBetHistory from './useBetHistory';
import { useTranslation } from "react-i18next";
import BetHistoryExpandBonus from './bet-expand-bonus/BetHistoryExpandBonus';
import { useEffect, useState } from 'react';
import BetHistoryWinningWayBonus from './bet-winning-way-bonus/BetHistoryWinningWayBonus';
import Pagination from '../common/pagination/pagination';
import { formatDateToISO, getQueryParam, splitStringByPrefix } from '../common/utility';
import NoRecord from '../common/no-record/NoRecord';
import { KM, KMprefixes, XIN } from '../common/constant';

interface BetHistoryProps {
	isSub?: boolean;
	gameData?: IBetHistoryDetailsResponse;
}

export default function BetHistory({ isSub = false, gameData }: BetHistoryProps) {
	const isKM = window.__env__?.REACT_APP_OPERATOR_ID == KM;
	const isXIN = window.__env__?.REACT_APP_OPERATOR_ID == XIN;

	const roundId = isKM ? splitStringByPrefix(getQueryParam('txnid') ?? '', KMprefixes).rest : getQueryParam('billno');
	const lang = getQueryParam("language");
	const billremark = gameData?.roundId ?? getQueryParam('billremark') ?? '?';
	const loginname = getQueryParam("loginname") ?? '?';
	const billtime = gameData?.createdAt ?? getQueryParam("billtime") ?? '?';
	const currency = getQueryParam("currency") ?? '?';
	const endpoint =
		isKM ?
			`${window.__env__?.REACT_APP_BO_SERVICE}/bethistory/${roundId}?OperatorId=${window.__env__?.REACT_APP_OPERATOR_ID}` :
			isXIN ?
				`${window.__env__?.REACT_APP_BO_SERVICE}/bethistory/${roundId}?UpdatedAt=${formatDateToISO(billtime ?? '')}&OperatorId=${window.__env__?.REACT_APP_OPERATOR_ID}&Username=${loginname}` :
				`${window.__env__?.REACT_APP_BO_SERVICE}/bethistory/${roundId}?UpdatedAt=${formatDateToISO(billtime ?? '')}&OperatorId=${window.__env__?.REACT_APP_OPERATOR_ID}`;
	const { fetchBetData, transformData } = useBetHistory(endpoint, billremark, loginname, billtime, currency);
	const gameConfig: GameConfigMap = config as GameConfigMap;
	const { i18n } = useTranslation();
	const [fetchedData, setFetchedData] = useState<IBetHistoryResponse>({
		baseGameResponse: {} as IBetHistoryDetailsResponse,
		freeGameResponse: [] as IBetHistoryDetailsResponse[]
	});
	const [transformedData, setTransformedData] = useState<any>(null);
	const [freeGameData, setFreeGameData] = useState<IBetHistoryDetailsResponse[]>([]);
	const [shouldDisplayNoRecordFound, setShouldDisplayNoRecordFound] = useState<boolean>(false);
	const [conf, setConf] = useState<any>(null);
	const [showFreeGame, setShowFreeGame] = useState<boolean>(false);

	// // Pagination
	const [currentIndex, setCurrentIndex] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState<number>(0);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const paginate = (pageNumber: any) => setCurrentIndex(pageNumber);

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetchBetData();
			if (data)
				setFetchedData(data);
		};
		if (!isSub) {
			fetchData();
		}
		i18n.changeLanguage(lang ?? 'en');
	}, [])

	useEffect(() => {
		const getBetDetails = async () => {
			const displayData = await transformData(isSub ? gameData as IBetHistoryDetailsResponse : fetchedData?.baseGameResponse)
			setTransformedData(displayData);
		}
		if (fetchedData) {
			getBetDetails();
			if (fetchedData?.freeGameResponse?.length > 0) {
				setFreeGameData(fetchedData.freeGameResponse);
			}
		}
	}, [fetchedData])

	useEffect(() => {
		setShouldDisplayNoRecordFound(!transformedData?.betHistoryFullDetails || !transformedData?.betResultData);
		setConf(transformedData?.betHistoryFullDetails ? gameConfig[transformedData?.betHistoryFullDetails?.codeId] : null)
		setRecordsPerPage((transformedData?.betResultData?.symbolResult?.totalRows ?? 0) * (transformedData?.betResultData?.symbolResult?.totalColumns ?? 0))
		setTotalRecords(transformedData?.betResultData?.symbolResult?.symbols?.length)
	}, [transformedData])

	return (
		<div className={styles.container}>
			<BetHistoryTitle loginName={transformedData?.titleData?.loginName || ''} billTime={transformedData?.titleData?.billTime || ''} billId={transformedData?.titleData?.billId || ''} isSub={isSub} />
			{shouldDisplayNoRecordFound ? (
				<NoRecord />
			) : (
				<>
					<BetHistoryResult gameType={transformedData?.betResultData?.gameType} symbolResult={transformedData?.betResultData?.symbolResult} betDetails={transformedData?.betResultData?.betDetails} assetPath={transformedData?.betResultData?.assetPath} height={conf?.betResultHeight}
						currentIndex={currentIndex} codeId={transformedData?.betResultData?.codeId} showFreeGame={showFreeGame} setShowFreeGame={setShowFreeGame} showFreeGameBtn={freeGameData.length > 0} />

					{transformedData?.betHistoryFullDetails?.hasFreegame && (
						<BetHistoryScatterBonus data={transformedData?.scatterResultData?.data} assetPath={transformedData?.scatterResultData?.assetPath} currency={transformedData?.scatterResultData?.currency} />
					)}

					{transformedData?.betHistoryFullDetails && transformedData?.betHistoryFullDetails?.expandSymbolPayout?.length > 0 ?
						<BetHistoryExpandBonus data={transformedData?.expandSymbolResultData?.data} assetPath={transformedData?.expandSymbolResultData?.assetPath} currency={transformedData?.expandSymbolResultData?.currency} />
						: null
					}

					{transformedData?.betHistoryFullDetails && transformedData?.betHistoryFullDetails?.specialSymbolPayout?.length > 0 && transformedData?.betHistoryFullDetails?.winningWayPayout?.length < 1 ?
						<BetHistorySpecialSymbolBonus data={transformedData?.specialSymbolResultData?.data} assetPath={transformedData?.specialSymbolResultData?.assetPath} currency={transformedData?.specialSymbolResultData?.currency} />
						: null
					}

					{transformedData?.betHistoryFullDetails && transformedData?.betHistoryFullDetails.winningWayPayout?.length > 0 ?
						<BetHistoryWinningWayBonus data={transformedData?.winningWayBonusData?.data} assetPath={transformedData?.winningWayBonusData?.assetPath} currency={transformedData?.winningWayBonusData?.currency}
							currentIndex={currentIndex} codeId={transformedData?.winningWayBonusData?.codeId} />
						: null
					}

					{transformedData?.betPayoutData && transformedData?.betPayoutData?.data && transformedData?.betPayoutData?.data?.length > 0 ?
						<BetHistoryPayout data={transformedData?.betPayoutData?.data} currency={transformedData?.betPayoutData?.currency} currentIndex={currentIndex} showMultiplier={transformedData?.betPayoutData?.showMultiplier} />
						: null
					}

					<Pagination postsPerPage={recordsPerPage} totalPosts={totalRecords} currentIndex={currentIndex} paginate={paginate} />
				</>
			)}
			{showFreeGame && freeGameData.map((gameData, index) => (
				<BetHistory isSub={true} gameData={gameData} />
			))}
		</div>
	)
};