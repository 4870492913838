import styles from './pagination.module.scss';

export default function pagination({ postsPerPage, totalPosts, currentIndex, paginate }: any) {

	const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (currentIndex === 0 || pageNumbers.length < 2) {
        return null;
    }

    const onPrevious = () => {
        paginate(currentIndex - 1);
    };

    const onNext = () => {
        paginate(currentIndex + 1);
    };

    let lastPage = pageNumbers[pageNumbers.length - 1];

    return (
        <nav className={styles.center}>
            <ul className={styles.container}>
                <li onClick={onPrevious} className={[styles.pagination_item, (currentIndex === 1 ? styles.disabled : "")].join(" ")}  >
                    <div className={[styles.arrow, styles.left].join(" ")} />
                </li>

                {pageNumbers.map(number => (
                <li  onClick={() => paginate(number)}  key={number} className={[styles.pagination_item, (number === currentIndex ? styles.selected : "")].join(" ")}>
                    {number}
                </li>
                ))}

                <li onClick={onNext} className={[styles.pagination_item, (currentIndex === lastPage ? styles.disabled : "")].join(" ")} >
                    <div className={[styles.arrow, styles.right].join(" ")} />
                </li>
            </ul>
        </nav>
	);
}