import styles from './BetHistorySpecialSymbolBonus.module.scss';
import { useTranslation } from "react-i18next";
import { IBetHistorySpecialSymbolBonus } from '../../common/interface';

export default function BetHistorySpecialSymbolBonus({ data, assetPath, currency }: IBetHistorySpecialSymbolBonus) {

	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<table>
				<thead>
					<tr>
						<th>{t('special_payout.win_icon')}</th>
						<th>{t('special_payout.gross_win', { currency: currency })}</th>
					</tr>
				</thead>
				<tbody>
					{data?.map((specialPayout, index) => (
						<tr key={index}>
							<td>
								<img src={`${assetPath + `/${specialPayout.symbol.toString()}`}.png`} alt="Scatter" className={styles.special_payout_image} />
							</td>
							<td>{specialPayout.payout}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}