import styles from './BetHistoryScatterBonus.module.scss';
import { useTranslation } from "react-i18next";
import { IBetHistoryScatterBonus } from '../../common/interface';

export default function BetHistoryScatterBonus({ data, assetPath, currency }: IBetHistoryScatterBonus) {

	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<div className={styles.large}>{t('scatter_payout.scatter')}</div>
			<div className={styles.image}>
				<img src={`${assetPath + `/${data?.symbol.toString()}`}.png`} alt="Scatter" className={styles.scatter_image} />
			</div>
			<div className={styles.header}>{t('scatter_payout.symbol_count')}</div>
			<div className={styles.header}>{t('scatter_payout.multiplier')}</div>
			<div className={styles.header}>{t('scatter_payout.gross_win', { currency: currency })}</div>

			<div className={styles.grid_item}>{data?.totalSymbol}</div>
			<div className={styles.grid_item}>{data?.multiplier}</div>
			<div className={styles.grid_item}>{data?.payout}</div>
		</div>
	);
}