import styles from './BetHistoryWinningWayBonus.module.scss';
import { useTranslation } from "react-i18next";
import { IBetHistoryWinningWayBonus } from '../../common/interface';

export default function BetHistoryWinningWayBonus({ data, assetPath, currency, currentIndex, codeId }: IBetHistoryWinningWayBonus) {

	const { t } = useTranslation();

	const currentRoundData = data?.filter(w => (codeId == "SL8" || codeId == "SL36" ? w.round + 1 : w.round) == currentIndex);
	const isNormalWayGame = codeId != "TS04";

	return (
		<div className={styles.container}>
			{currentRoundData && currentRoundData.length > 0 ?
				<table>
					<thead>
						<tr>
							<th>{t('way_payout.win_icon')}</th>
							<th>{t('way_payout.win_count')}</th>
							{isNormalWayGame ? <th>{t('way_payout.row_max')}</th> : null}
							<th>{t('way_payout.multiplier')}</th>
							<th>{t('way_payout.payout', { currency: currency })}</th>
						</tr>
					</thead>
					<tbody>
						{currentRoundData?.map((wayPayout, index) => (
							<tr key={index}>
								<td>
									<img src={`${assetPath + `/${wayPayout.winSymbol.toString()}`}.png`} alt="Scatter" className={styles.special_payout_image} />
								</td>
								<td>{wayPayout.totalSymbol}</td>
								{isNormalWayGame ? <td>{wayPayout.rowmax}</td> : null}
								<td>{wayPayout.multiplier}</td>
								<td>{wayPayout.payout}</td>
							</tr>
						))}
					</tbody>
				</table>
				: null
			}
		</div>
	);
}