import './App.css';
import BetHistory from './scripts/bet-history/BetHistory';
import JackpotHistory from './scripts/jackpot-history/JackpotHistory';
import packageJson from '../package.json';

// configure homepage in package.json
// homepage: "/" for dev
// homepage: "/jackpot-result" for prod jackpot history api
// homepage: "/bet-result" for prod bet history api
// const { homepage } = packageJson;

function App() {
	return (
		<div className="App">
			<BetHistory />
		</div>
	);
}

export default App;
